
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getEmailNotificationList,
  addNotificationEmail,
  deleteEmail,
} from "@/store/api/devices";
import { number } from "yup/lib/locale";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";

interface EmailRules {
  notification_rule_id: number;
  name: string;
  email: string;
}
export default defineComponent({
  name: "EmailModal",
  props: {
    data: { type: Object, required: true },
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup(props) {
    const formState = ref({});
    const email = ref<any>();
    const payload = ref<EmailRules>({
      notification_rule_id: props.data.id,
      name: "",
      email: "",
    });
    watch(
      () => props.data.id,
      (val) => {
        payload.value.notification_rule_id = val;
        if (payload.value.notification_rule_id) {
          getEmailNotificationList(payload.value.notification_rule_id).then(
            (r) => {
              email.value = r.data;
            }
          );
        }
      },
      { deep: true, immediate: true }
    );

    const validationSchema = Yup.object().shape({
      userName: Yup.string().required().label("Username"),
      userEmail: Yup.string().email().required().label("Email"),
    });

    const submit = async () => {
      payload.value.notification_rule_id = props.data.id;
      await addNotificationEmail(payload.value).then((response) => {
        let re = response.success;
        if (re === true) {
          Swal.fire({
            text: "Email has been successfully added!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else {
          Swal.fire({
            text: "Email already exist!",
            icon: "danger",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
      payload.value = {
        notification_rule_id: props.data.id,
        name: "",
        email: "",
      };

      email.value = await getEmailNotificationList(
        payload.value.notification_rule_id
      ).then((response) => response.data);
    };

    const deleteEmailNotification = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteEmail(id);
          console.log(resp);
        }
        email.value = await getEmailNotificationList(
          payload.value.notification_rule_id
        ).then((response) => response.data);
      });
    };
    return {
      payload,
      email,
      submit,
      deleteEmailNotification,
      validationSchema,
      formState,
    };
  },
});
