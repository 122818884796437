
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getMobileNotificationList,
  addNotificationMobile,
  deleteMobile,
} from "@/store/api/devices";
import { ErrorMessage, Field, Form } from "vee-validate";
import { number } from "yup/lib/locale";
import * as Yup from "yup";

interface SMSRules {
  notification_rule_id: number;
  name: string;
  mobile: string;
}
export default defineComponent({
  name: "SMSModal",
  props: {
    data: { type: Object, required: true },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup(props) {
    const mobile = ref<any>();
    const payload = ref<SMSRules>({
      notification_rule_id: props.data.id,
      name: "",
      mobile: "",
    });
    watch(
      () => props.data.id,
      (val) => {
        payload.value.notification_rule_id = val;
        if (payload.value.notification_rule_id) {
          getMobileNotificationList(payload.value.notification_rule_id).then(
            (r) => {
              mobile.value = r.data;
            }
          );
        }
      },
      { deep: true, immediate: true }
    );

    const validationSchema = Yup.object().shape({
      userName: Yup.string().required().label("Username"),
      userMobile: Yup.string().required().label("Mobile"),
    });

    const submit = async () => {
      payload.value.notification_rule_id = props.data.id;
      await addNotificationMobile(payload.value).then((response) => {
        let re = response.success;
        if (re === true) {
          Swal.fire({
            text: "Mobile has been successfully added!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else {
          Swal.fire({
            text: "Mobile Number already exist!",
            icon: "danger",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
      mobile.value = await getMobileNotificationList(
        payload.value.notification_rule_id
      ).then((response) => response.data);
      payload.value.mobile = "";
      payload.value.name = "";
    };

    const deleteMobileNotification = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteMobile(id);
          console.log(resp);
        }
        mobile.value = await getMobileNotificationList(
          payload.value.notification_rule_id
        ).then((response) => response.data);
      });
    };
    return {
      payload,
      mobile,
      submit,
      deleteMobileNotification,
      validationSchema,
    };
  },
});
