import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, vModelText as _vModelText, createTextVNode as _createTextVNode, vShow as _vShow } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  "data-bs-backdrop": "static",
  "data-bs-keyboard": "false",
  tabindex: "-1",
  id: "kt_modal_3",
  ref: "closeModal"
}
const _hoisted_2 = {
  class: "modal-dialog modal-lg modal-dialog-centered",
  style: {"top":"50px"}
}
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = { class: "modal-header bg-primary" }
const _hoisted_5 = {
  key: 0,
  class: "modal-title h5 text-uppercase text-white",
  id: "exampleModalLabel",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_6 = {
  key: 1,
  class: "modal-title h5 text-uppercase text-white",
  id: "exampleModalLabel",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_7 = { class: "modal-body rounded-6" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["checked"]
const _hoisted_11 = ["checked"]
const _hoisted_12 = {
  key: 0,
  class: "show-alert-msg"
}
const _hoisted_13 = ["checked"]
const _hoisted_14 = {
  key: 0,
  class: "show-alert-msg"
}
const _hoisted_15 = ["checked"]
const _hoisted_16 = {
  key: 0,
  class: "show-alert-msg"
}
const _hoisted_17 = ["checked"]
const _hoisted_18 = {
  key: 0,
  class: "show-alert-msg"
}
const _hoisted_19 = ["checked"]
const _hoisted_20 = {
  key: 0,
  class: "show-alert-msg"
}
const _hoisted_21 = ["checked"]
const _hoisted_22 = ["checked"]
const _hoisted_23 = ["checked"]
const _hoisted_24 = ["checked"]
const _hoisted_25 = ["checked"]
const _hoisted_26 = ["checked"]
const _hoisted_27 = ["checked"]
const _hoisted_28 = ["checked"]
const _hoisted_29 = ["checked"]
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 0,
  class: "spinner-border spinner-border-sm text-white"
}
const _hoisted_32 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.notification_rule?.id)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Add Company Subscription "))
            : _createCommentVNode("v-if", true),
          (_ctx.notification_rule?.id)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Update Company Subscription "))
            : _createCommentVNode("v-if", true),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-icon btn-sm",
            "data-bs-dismiss": "modal",
            "aria-label": "Close",
            id: "addnewnotificationmodal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalClose()))
          }, _cache[29] || (_cache[29] = [
            _createElementVNode("i", {
              class: "bi bi-x-circle-fill light-text fs-3",
              style: {"color":"white"}
            }, null, -1 /* HOISTED */)
          ])),
          _createCommentVNode("end::Close")
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_b_container, { class: "bv-example-row" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, { class: "text-center my-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                      _createElementVNode("h6", { class: "required fs-5 mt-1 ms-5 ps-5 text-uppercase text-start text-primary" }, " Select Company ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }),
                  _createVNode(_component_b_col, { cols: "5" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("select", {
                        class: "form-select border border-primary",
                        name: "se_account_id",
                        "aria-label": "Default select example",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.se_account_id) = $event)),
                        style: {"background-color":"#f7fdff !important","border":"blue"},
                        disabled: _ctx.notification_rule?.id ? true : false
                      }, [
                        _cache[31] || (_cache[31] = _createElementVNode("option", {
                          value: "",
                          selected: ""
                        }, "Select Company", -1 /* HOISTED */)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: `${customer.id}`,
                            key: customer.id
                          }, _toDisplayString(customer.name) + " (" + _toDisplayString(customer.id) + ") ", 9 /* TEXT, PROPS */, _hoisted_9))
                        }), 128 /* KEYED_FRAGMENT */))
                      ], 8 /* PROPS */, _hoisted_8), [
                        [_vModelSelect, _ctx.payload.se_account_id]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("firt row"),
              _createVNode(_component_b_row, { class: "text-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2",
                        style: {"float":"right","clear":"both"},
                        type: "checkbox",
                        value: "1",
                        id: "",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.enable_fill_notification) = $event)),
                        checked: _ctx.payload.enable_fill_notification == 1 ? 1 : 0,
                        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getValue(_ctx.payload.enable_fill_notification,'fill_level')))
                      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_10), [
                        [_vModelCheckbox, _ctx.payload.enable_fill_notification]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[32] || (_cache[32] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, "Fill Level", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode(" <b-col cols=\"6\">\r\n                <div v-show=\"payload.enable_fill_notification == true\">\r\n                   <input\r\n                  type=\"number\"\r\n                  v-model=\"payload.fill_value\"\r\n                  placeholder=\"Add the Fill Level\"\r\n                  style=\"background-color: #ebf8ff; !important vertical-align: middle\"\r\n                  class=\"w-50 p-1 text-center fs-5 border border-primary rounded-2\"\r\n                />\r\n                <span class=\"fs-5 ms-1 text-muted\">%</span> <br />\r\n                <div\r\n                  v-if=\"payload.fill_value > 100 || payload.fill_value < 0\"\r\n                  class=\"show-alert-msg\"\r\n                  >Enter Greater than 0 and Less than 100</div\r\n                >\r\n                </div>\r\n               \r\n              </b-col> ")
                ]),
                _: 1 /* STABLE */
              }),
              _withDirectives(_createElementVNode("div", null, [
                _cache[45] || (_cache[45] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
                _createCommentVNode(" minor fill level "),
                _createVNode(_component_b_row, { class: "text-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, {
                      cols: "2",
                      class: "text-right"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-check-input h-20px w-20px border border-primary mx-2",
                          style: {"float":"right","clear":"both"},
                          type: "checkbox",
                          value: "1",
                          id: "",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.enable_minor_fill_level) = $event)),
                          checked: _ctx.payload.enable_minor_fill_level == 1 ? 1 : 0,
                          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setValues(_ctx.payload.enable_minor_fill_level,'enable_minor_fill_level')))
                        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_11), [
                          [_vModelCheckbox, _ctx.payload.enable_minor_fill_level]
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_col, { cols: "4" }, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createElementVNode("p", { class: "fs-5 text-muted text-start" }, "Minor Fill Level", -1 /* HOISTED */)
                      ])),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("div", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.minor_fill_value) = $event)),
                            placeholder: "Add the minor fill level",
                            style: {"background-color":"#ebf8ff","!important vertical-align":"middle"},
                            class: "w-50 p-1 text-center fs-5 border border-primary rounded-2"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.payload.minor_fill_value]
                          ]),
                          _cache[34] || (_cache[34] = _createElementVNode("span", { class: "fs-5 ms-1 text-muted" }, "%", -1 /* HOISTED */)),
                          _cache[35] || (_cache[35] = _createTextVNode()),
                          _cache[36] || (_cache[36] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                          (_ctx.payload.fill_value > 100 || _ctx.payload.fill_value < 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, "Enter Greater than 0 and Less than 100"))
                            : _createCommentVNode("v-if", true)
                        ], 512 /* NEED_PATCH */), [
                          [_vShow, _ctx.payload.enable_minor_fill_level == true]
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" Major Fill Level "),
                _createVNode(_component_b_row, { class: "text-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, {
                      cols: "2",
                      class: "text-right"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-check-input h-20px w-20px border border-primary mx-2",
                          style: {"float":"right","clear":"both"},
                          type: "checkbox",
                          value: "1",
                          id: "",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.enable_major_fill_level) = $event)),
                          checked: _ctx.payload.enable_major_fill_level == 1 ? 1 : 0,
                          onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setValues(_ctx.payload.enable_major_fill_level,'enable_major_fill_level')))
                        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13), [
                          [_vModelCheckbox, _ctx.payload.enable_major_fill_level]
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_col, { cols: "4" }, {
                      default: _withCtx(() => _cache[37] || (_cache[37] = [
                        _createElementVNode("p", { class: "fs-5 text-muted text-start" }, "Major Fill Level", -1 /* HOISTED */)
                      ])),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("div", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.major_fill_value) = $event)),
                            placeholder: "Add the Fill Level",
                            style: {"background-color":"#ebf8ff","!important vertical-align":"middle"},
                            class: "w-50 p-1 text-center fs-5 border border-primary rounded-2"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.payload.major_fill_value]
                          ]),
                          _cache[38] || (_cache[38] = _createElementVNode("span", { class: "fs-5 ms-1 text-muted" }, "%", -1 /* HOISTED */)),
                          _cache[39] || (_cache[39] = _createTextVNode()),
                          _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                          (_ctx.payload.fill_value > 100 || _ctx.payload.fill_value < 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, "Enter Greater than 0 and Less than 100"))
                            : _createCommentVNode("v-if", true)
                        ], 512 /* NEED_PATCH */), [
                          [_vShow, _ctx.payload.enable_major_fill_level == true]
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" Critial Fill Level "),
                _createVNode(_component_b_row, { class: "text-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, {
                      cols: "2",
                      class: "text-right"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-check-input h-20px w-20px border border-primary mx-2",
                          style: {"float":"right","clear":"both"},
                          type: "checkbox",
                          value: "1",
                          id: "",
                          checked: _ctx.payload.enable_critical_fill_level == 1 ? 1 : 0,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.enable_critical_fill_level) = $event)),
                          onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setValues(_ctx.payload.enable_critical_fill_level,'enable_critical_fill_level')))
                        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_15), [
                          [_vModelCheckbox, _ctx.payload.enable_critical_fill_level]
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_col, {
                      cols: "4",
                      class: ""
                    }, {
                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                        _createElementVNode("p", { class: "fs-5 text-muted text-start" }, "Critical Fill Level", -1 /* HOISTED */)
                      ])),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("div", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.critical_fill_value) = $event)),
                            placeholder: "Add the Fill Level",
                            style: {"background-color":"#ebf8ff","!important vertical-align":"middle"},
                            class: "w-50 p-1 text-center fs-5 border border-primary rounded-2"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.payload.critical_fill_value]
                          ]),
                          _cache[42] || (_cache[42] = _createElementVNode("span", { class: "fs-5 ms-1 text-muted" }, "%", -1 /* HOISTED */)),
                          _cache[43] || (_cache[43] = _createTextVNode()),
                          _cache[44] || (_cache[44] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                          (_ctx.payload.fill_value > 100 || _ctx.payload.fill_value < 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, "Enter Greater than 0 and Less than 100"))
                            : _createCommentVNode("v-if", true)
                        ], 512 /* NEED_PATCH */), [
                          [_vShow, _ctx.payload.enable_critical_fill_level == true]
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _cache[46] || (_cache[46] = _createElementVNode("hr", null, null, -1 /* HOISTED */))
              ], 512 /* NEED_PATCH */), [
                [_vShow, _ctx.payload.enable_fill_notification == true]
              ]),
              _createCommentVNode("firt row end"),
              _createCommentVNode(" <hr /> "),
              _createCommentVNode("2nd row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 leftright",
                        type: "checkbox",
                        value: "1",
                        id: "",
                        checked: _ctx.payload.enable_temperature_notification == 1 ? 1 : 0,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payload.enable_temperature_notification) = $event)),
                        onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.getValue(_ctx.payload.enable_temperature_notification,'temperature_level')))
                      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_17), [
                        [_vModelCheckbox, _ctx.payload.enable_temperature_notification]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[47] || (_cache[47] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, "Temperature", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          placeholder: "Add the Temperature level",
                          style: {"background-color":"#ebf8ff"},
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.payload.temperature_value) = $event)),
                          class: "w-50 text-center fs-5 p-1 border border-primary rounded-2"
                        }, null, 512 /* NEED_PATCH */), [
                          [_vModelText, _ctx.payload.temperature_value]
                        ]),
                        _cache[48] || (_cache[48] = _createElementVNode("span", { class: "fs-5 ms-1 text-muted" }, "C", -1 /* HOISTED */)),
                        _cache[49] || (_cache[49] = _createTextVNode()),
                        _cache[50] || (_cache[50] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                        (_ctx.payload.temperature_value > 100)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, "Enter Less than 100"))
                          : _createCommentVNode("v-if", true)
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.payload.enable_temperature_notification == true]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("2nd row end  "),
              _createCommentVNode(" <hr /> "),
              _createCommentVNode("3rd row start "),
              _createVNode(_component_b_row, { class: "text-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        checked: _ctx.payload.enable_battery_notification == 1 ? 1 : 0,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.payload.enable_battery_notification) = $event)),
                        onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.getValue(_ctx.payload.enable_battery_notification,'battery_level')))
                      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_19), [
                        [_vModelCheckbox, _ctx.payload.enable_battery_notification]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[51] || (_cache[51] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, "Battery", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          placeholder: "Add the Battery Level",
                          style: {"background-color":"#ebf8ff"},
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.payload.battery_value) = $event)),
                          class: "w-50 text-center fs-5 p-1 border border-primary rounded-2"
                        }, null, 512 /* NEED_PATCH */), [
                          [_vModelText, _ctx.payload.battery_value]
                        ]),
                        _cache[52] || (_cache[52] = _createElementVNode("span", { class: "fs-5 ms-1 text-muted" }, "%", -1 /* HOISTED */)),
                        _cache[53] || (_cache[53] = _createTextVNode()),
                        _cache[54] || (_cache[54] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                        (
                    _ctx.payload.battery_value > 100 || _ctx.payload.battery_value < 0
                  )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, "Enter Greater than 0 and Less than 100"))
                          : _createCommentVNode("v-if", true)
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.payload.enable_battery_notification == true]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("3rd row end "),
              _createCommentVNode(" <hr /> "),
              _createCommentVNode("4rth row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.payload.enable_error_notification) = $event)),
                        checked: _ctx.payload.enable_error_notification == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_21), [
                        [_vModelCheckbox, _ctx.payload.enable_error_notification]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[55] || (_cache[55] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, "511 Error", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("4rth row end "),
              _createCommentVNode("5th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.payload.enable_offline_notification) = $event)),
                        checked: _ctx.payload.enable_offline_notification == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_22), [
                        [_vModelCheckbox, _ctx.payload.enable_offline_notification]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[56] || (_cache[56] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Offline Notification ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("5th row end "),
              _createCommentVNode("6th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.payload.enable_inactive_notification) = $event)),
                        checked: _ctx.payload.enable_inactive_notification == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_23), [
                        [_vModelCheckbox, _ctx.payload.enable_inactive_notification]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[57] || (_cache[57] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Inactive Notification ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("6th row end "),
              _cache[66] || (_cache[66] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
              _createCommentVNode("7th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.payload.enable_fire_alarm) = $event)),
                        checked: _ctx.payload.enable_fire_alarm == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_24), [
                        [_vModelCheckbox, _ctx.payload.enable_fire_alarm]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => _cache[58] || (_cache[58] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Fire Alarm Email Service ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("7th row end "),
              _createCommentVNode("8th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.payload.enable_fire_alarm_sms) = $event)),
                        checked: _ctx.payload.enable_fire_alarm_sms == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_25), [
                        [_vModelCheckbox, _ctx.payload.enable_fire_alarm_sms]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => _cache[59] || (_cache[59] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Fire Alarm SMS Service ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("8th row end "),
              _cache[67] || (_cache[67] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
              _createCommentVNode("9th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.payload.enable_pickup_event) = $event)),
                        checked: _ctx.payload.enable_pickup_event == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_26), [
                        [_vModelCheckbox, _ctx.payload.enable_pickup_event]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => _cache[60] || (_cache[60] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Pickup Event Email Service ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("9th row end "),
              _createCommentVNode("10th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.payload.enable_pickup_event_sms) = $event)),
                        checked: _ctx.payload.enable_pickup_event_sms == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_27), [
                        [_vModelCheckbox, _ctx.payload.enable_pickup_event_sms]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => _cache[61] || (_cache[61] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Pickup Event SMS Service ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("10th row end "),
              _cache[68] || (_cache[68] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
              _createCommentVNode("11th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.payload.enable_temper_event) = $event)),
                        checked: _ctx.payload.enable_temper_event == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_28), [
                        [_vModelCheckbox, _ctx.payload.enable_temper_event]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => _cache[62] || (_cache[62] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Temper Event Email Service ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("11th row end "),
              _createCommentVNode("12th row start "),
              _createVNode(_component_b_row, { class: "text-center my-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input h-20px w-20px border border-primary mx-2 text-right leftright",
                        type: "checkbox",
                        value: "",
                        id: "flexCheckDefault",
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.payload.enable_temper_event_sms) = $event)),
                        checked: _ctx.payload.enable_temper_event_sms == 1 ? 1 : 0
                      }, null, 8 /* PROPS */, _hoisted_29), [
                        [_vModelCheckbox, _ctx.payload.enable_temper_event_sms]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, { cols: "6" }, {
                    default: _withCtx(() => _cache[63] || (_cache[63] = [
                      _createElementVNode("p", { class: "fs-5 text-muted text-start" }, " Enable Temper Event SMS Service ", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("12th row end "),
              _createCommentVNode("buttons "),
              _createVNode(_component_b_row, { class: "text-center mb-3 mt-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  }),
                  _createVNode(_component_b_col, {
                    cols: "8",
                    class: "mb-3 modal-btn"
                  }, {
                    default: _withCtx(() => [
                      _cache[65] || (_cache[65] = _createElementVNode("button", {
                        class: "btn btn-danger text-uppercase fs-7 ml-10 buttonsize",
                        "data-bs-dismiss": "modal"
                      }, " Cancel ", -1 /* HOISTED */)),
                      _createElementVNode("button", {
                        class: "btn btn-success text-uppercase fs-7 ml-5 buttonsize",
                        onClick: _cache[28] || (_cache[28] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
                      }, [
                        (_ctx.loading)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                              _cache[64] || (_cache[64] = _createTextVNode("Saving ")),
                              (_ctx.loading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_31))
                                : _createCommentVNode("v-if", true)
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_32, "Save"))
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_col, {
                    cols: "2",
                    class: "text-right"
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createCommentVNode("buttons ")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ])
  ], 512 /* NEED_PATCH */))
}