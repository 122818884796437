import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"width":"100%","border":"0px"}
}
const _hoisted_2 = {
  class: "card-header border-0",
  style: {"margin-top":"-30px"}
}
const _hoisted_3 = {
  key: 0,
  class: "card-title"
}
const _hoisted_4 = { class: "card-body pt-0 border-0" }
const _hoisted_5 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["value"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "form-check form-check-custom form-check-solid custommargin" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["value"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["value"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "form-check form-check-custom form-check-solid custommarginn" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = ["value"]
const _hoisted_23 = { key: 1 }
const _hoisted_24 = ["value"]
const _hoisted_25 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = ["value"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = ["value"]
const _hoisted_30 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = ["value"]
const _hoisted_33 = { key: 1 }
const _hoisted_34 = ["value"]
const _hoisted_35 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = ["value"]
const _hoisted_38 = { key: 1 }
const _hoisted_39 = ["value"]
const _hoisted_40 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_41 = { key: 0 }
const _hoisted_42 = ["value"]
const _hoisted_43 = { key: 1 }
const _hoisted_44 = ["value"]
const _hoisted_45 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = ["value"]
const _hoisted_48 = { key: 1 }
const _hoisted_49 = ["value"]
const _hoisted_50 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = ["value"]
const _hoisted_53 = { key: 1 }
const _hoisted_54 = ["value"]
const _hoisted_55 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_56 = { key: 0 }
const _hoisted_57 = ["value"]
const _hoisted_58 = { key: 1 }
const _hoisted_59 = ["value"]
const _hoisted_60 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_61 = { key: 0 }
const _hoisted_62 = ["value"]
const _hoisted_63 = { key: 1 }
const _hoisted_64 = ["value"]
const _hoisted_65 = { class: "d-flex justify-content-start" }
const _hoisted_66 = ["onClick"]
const _hoisted_67 = ["onClick"]
const _hoisted_68 = ["onClick"]
const _hoisted_69 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_EmailModal = _resolveComponent("EmailModal")!
  const _component_SmsModal = _resolveComponent("SmsModal")!
  const _component_AddNotification = _resolveComponent("AddNotification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("h4", { class: "text-primary" }, "Notifications"),
          _createCommentVNode("end::Search")
        ], -1 /* HOISTED */)),
        (_ctx.permissions.includes('add-subscriptions'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createCommentVNode("begin::Search"),
              _cache[0] || (_cache[0] = _createElementVNode("button", {
                class: "btn btn-primary text-uppercase",
                "data-bs-toggle": "modal",
                "data-bs-target": "#kt_modal_3"
              }, " Add Subscription ", -1 /* HOISTED */)),
              _createCommentVNode("end::Search")
            ]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode("begin::Card title")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.notification,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange
        }, {
          "cell-company_name": _withCtx(({ row: bin }) => [
            _createElementVNode("div", null, _toDisplayString(bin.company_name), 1 /* TEXT */)
          ]),
          "cell-enable_fill_notification": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_5, [
              (bin.enable_fill_notification == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_fill_notification}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_7)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_fill_notification}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_9)
                  ]))
            ])
          ]),
          "cell-enable_temperature_notification": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_10, [
              (bin.enable_temperature_notification == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_temperature_notification}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_12)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_temperature_notification}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_14)
                  ]))
            ])
          ]),
          "cell-enable_battery_notification": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_15, [
              (bin.enable_battery_notification == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_battery_notification}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_17)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_battery_notification}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_19)
                  ]))
            ])
          ]),
          "cell-enable_error_notification": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_20, [
              (bin.enable_error_notification == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_error_notification}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_22)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_error_notification}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_24)
                  ]))
            ])
          ]),
          "cell-enable_offline_notification": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_25, [
              (bin.enable_offline_notification == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_offline_notification}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_27)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_offline_notification}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_29)
                  ]))
            ])
          ]),
          "cell-enable_inactive_notification": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_30, [
              (bin.enable_inactive_notification == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_inactive_notification}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_32)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_inactive_notification}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_34)
                  ]))
            ])
          ]),
          "cell-enable_fire_alarm": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_35, [
              (bin.enable_fire_alarm == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_fire_alarm}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_37)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_fire_alarm}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_39)
                  ]))
            ])
          ]),
          "cell-enable_fire_alarm_sms": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_40, [
              (bin.enable_fire_alarm_sms == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_fire_alarm_sms}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_42)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_fire_alarm_sms}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_44)
                  ]))
            ])
          ]),
          "cell-enable_pickup_event": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_45, [
              (bin.enable_pickup_event == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_pickup_event}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_47)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_48, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_pickup_event}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_49)
                  ]))
            ])
          ]),
          "cell-enable_pickup_event_sms": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_50, [
              (bin.enable_pickup_event_sms == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_pickup_event_sms}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_52)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_53, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_pickup_event_sms}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_54)
                  ]))
            ])
          ]),
          "cell-enable_temper_event": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_55, [
              (bin.enable_temper_event == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_temper_event}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_57)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_58, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_temper_event}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_59)
                  ]))
            ])
          ]),
          "cell-enable_temper_event_sms": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_60, [
              (bin.enable_temper_event_sms == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_temper_event_sms}`,
                      id: "flexCheckDefault",
                      checked: "",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_62)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_63, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      value: `${bin.enable_temper_event_sms}`,
                      id: "flexCheckDefault",
                      disabled: ""
                    }, null, 8 /* PROPS */, _hoisted_64)
                  ]))
            ])
          ]),
          "cell-id": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_65, [
              (_ctx.permissions.includes('edit-subscriptions'))
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: "fas fa-edit fa-lg text-success cursor-pointer",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_3",
                    onClick: ($event: any) => (_ctx.getId(bin.id, bin.company_name)),
                    title: "Edit"
                  }, null, 8 /* PROPS */, _hoisted_66))
                : _createCommentVNode("v-if", true),
              (_ctx.permissions.includes('add-email'))
                ? (_openBlock(), _createElementBlock("i", {
                    key: 1,
                    class: "fas fa-envelope fa-lg text-primary bg-white cursor-pointer",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_email",
                    onClick: ($event: any) => (_ctx.getId(bin.id, bin.company_name)),
                    title: "Add Email for Alerts"
                  }, null, 8 /* PROPS */, _hoisted_67))
                : _createCommentVNode("v-if", true),
              (_ctx.permissions.includes('add-mobile'))
                ? (_openBlock(), _createElementBlock("i", {
                    key: 2,
                    class: "fas fa-phone fa-lg text-warning bg-white cursor-pointer",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_sms",
                    onClick: ($event: any) => (_ctx.getId(bin.id, bin.company_name)),
                    title: "Add Mobile for Alerts"
                  }, null, 8 /* PROPS */, _hoisted_68))
                : _createCommentVNode("v-if", true),
              (_ctx.permissions.includes('delete-subscriptions'))
                ? (_openBlock(), _createElementBlock("i", {
                    key: 3,
                    onClick: ($event: any) => (_ctx.delete_notification(bin.id)),
                    class: "fas fa-trash fa-lg cursor-pointer text-danger",
                    title: "Delete"
                  }, null, 8 /* PROPS */, _hoisted_69))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"]),
        _createVNode(_component_EmailModal, { data: _ctx.data }, null, 8 /* PROPS */, ["data"]),
        _createVNode(_component_SmsModal, { data: _ctx.data }, null, 8 /* PROPS */, ["data"]),
        _createVNode(_component_AddNotification, {
          loadData: _ctx.loadData,
          data: _ctx.data
        }, null, 8 /* PROPS */, ["loadData", "data"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createCommentVNode("end::Card")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}