import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_sms"
}
const _hoisted_2 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header bg-primary" }
const _hoisted_5 = {
  class: "modal-title h5 text-uppercase text-white",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "row gy-5" }
const _hoisted_9 = { class: "col-md-4" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "col-md-4" }
const _hoisted_13 = { class: "fv-plugins-message-container" }
const _hoisted_14 = { class: "fv-help-block" }
const _hoisted_15 = { class: "row mt-5 justify-content-center" }
const _hoisted_16 = { class: "col-md-12" }
const _hoisted_17 = { class: "table" }
const _hoisted_18 = { class: "fs-5 px-20" }
const _hoisted_19 = { class: "fs-5" }
const _hoisted_20 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, " Add SMS subscription ( " + _toDisplayString(_ctx.data.company_name) + " ID: " + _toDisplayString(_ctx.data.id) + ") ", 1 /* TEXT */),
          _createCommentVNode("begin::Close"),
          _cache[2] || (_cache[2] = _createElementVNode("div", {
            class: "btn btn-icon btn-sm",
            "data-bs-dismiss": "modal",
            "aria-label": "Close",
            id: "downlinkclosemodal"
          }, [
            _createElementVNode("i", {
              class: "bi bi-x-circle-fill light-text fs-3",
              style: {"color":"white"}
            })
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Close")
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Form, {
              class: "form",
              onSubmit: _ctx.submit,
              "validation-schema": _ctx.validationSchema,
              "initial-values": _ctx.payload,
              ref: "formRef"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_Field, {
                      type: "text",
                      modelValue: _ctx.payload.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.name) = $event)),
                      class: "form-control form-control-solid border-primary mt-2",
                      placeholder: "Name",
                      name: "userName"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_ErrorMessage, { name: "userName" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_Field, {
                      type: "text",
                      modelValue: _ctx.payload.mobile,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.mobile) = $event)),
                      class: "form-control form-control-solid border-primary mt-2",
                      placeholder: "+447893932865",
                      name: "userMobile"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_ErrorMessage, { name: "userMobile" })
                      ])
                    ])
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("div", {
                    class: "col-md-3",
                    style: {"margin-left":"41px"}
                  }, [
                    _createElementVNode("button", {
                      type: "submit",
                      class: "btn btn-primary text-uppercase fs-6 fw-bold"
                    }, " Subscribe ")
                  ], -1 /* HOISTED */))
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onSubmit", "validation-schema", "initial-values"]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("table", _hoisted_17, [
                  _cache[4] || (_cache[4] = _createElementVNode("thead", { class: "fs-5 border-bottom-2 fw-bold" }, [
                    _createElementVNode("tr", { class: "text-uppercase" }, [
                      _createElementVNode("th", { class: "px-15" }, "Name"),
                      _createElementVNode("th", { class: "px-15" }, "Mobile"),
                      _createElementVNode("th", null, "Action")
                    ])
                  ], -1 /* HOISTED */)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobile, (customer, id) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        class: "border-bottom-2",
                        key: id
                      }, [
                        _createElementVNode("td", _hoisted_18, _toDisplayString(customer.name), 1 /* TEXT */),
                        _createElementVNode("td", _hoisted_19, _toDisplayString(customer.mobile), 1 /* TEXT */),
                        _createElementVNode("td", null, [
                          _createElementVNode("i", {
                            class: "fas fa-trash-alt fa-2x text-danger",
                            onClick: ($event: any) => (_ctx.deleteMobileNotification(customer.id))
                          }, null, 8 /* PROPS */, _hoisted_20)
                        ])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}